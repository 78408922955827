exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-v-1-analytics-account-tsx": () => import("./../../../src/pages/v1/analytics/account.tsx" /* webpackChunkName: "component---src-pages-v-1-analytics-account-tsx" */),
  "component---src-pages-v-1-analytics-emails-email-tsx": () => import("./../../../src/pages/v1/analytics/emails/email.tsx" /* webpackChunkName: "component---src-pages-v-1-analytics-emails-email-tsx" */),
  "component---src-pages-v-1-analytics-emails-index-tsx": () => import("./../../../src/pages/v1/analytics/emails/index.tsx" /* webpackChunkName: "component---src-pages-v-1-analytics-emails-index-tsx" */),
  "component---src-pages-v-1-analytics-people-index-tsx": () => import("./../../../src/pages/v1/analytics/people/index.tsx" /* webpackChunkName: "component---src-pages-v-1-analytics-people-index-tsx" */),
  "component---src-pages-v-1-analytics-people-lead-tsx": () => import("./../../../src/pages/v1/analytics/people/lead.tsx" /* webpackChunkName: "component---src-pages-v-1-analytics-people-lead-tsx" */),
  "component---src-pages-v-2-analytics-account-tsx": () => import("./../../../src/pages/v2/analytics/account.tsx" /* webpackChunkName: "component---src-pages-v-2-analytics-account-tsx" */),
  "component---src-pages-v-2-analytics-emails-email-tsx": () => import("./../../../src/pages/v2/analytics/emails/email.tsx" /* webpackChunkName: "component---src-pages-v-2-analytics-emails-email-tsx" */),
  "component---src-pages-v-2-analytics-emails-index-tsx": () => import("./../../../src/pages/v2/analytics/emails/index.tsx" /* webpackChunkName: "component---src-pages-v-2-analytics-emails-index-tsx" */),
  "component---src-pages-v-2-analytics-people-index-tsx": () => import("./../../../src/pages/v2/analytics/people/index.tsx" /* webpackChunkName: "component---src-pages-v-2-analytics-people-index-tsx" */),
  "component---src-pages-v-2-analytics-people-lead-tsx": () => import("./../../../src/pages/v2/analytics/people/lead.tsx" /* webpackChunkName: "component---src-pages-v-2-analytics-people-lead-tsx" */)
}

