import React from "react";
import { Icon, type IconProps } from "@chakra-ui/react";

export const SeventhSenseSvg = (props: IconProps) => {
  return (
    <Icon width="94px" height="40px" viewBox="0 0 94 40" fill="none" {...props}>
      <g id="seventh">
        <path
          d="M11.9983 8.43859C11.5633 6.17691 9.754 4.73292 6.62244 4.73292C3.4039 4.73292 1.21181 6.31609 1.22051 8.78654C1.21181 10.7351 2.41224 12.0225 4.97837 12.5531L7.25745 13.0315C8.48398 13.3012 9.0581 13.797 9.0581 14.5538C9.0581 15.4672 8.06644 16.1544 6.57025 16.1544C5.12625 16.1544 4.18679 15.5281 3.91712 14.3277L0.846458 14.6234C1.2379 17.0765 3.29951 18.5292 6.57895 18.5292C9.91927 18.5292 12.2766 16.7981 12.2853 14.2668C12.2766 12.3617 11.0501 11.1961 8.52747 10.6481L6.24839 10.1609C4.89139 9.85649 4.35206 9.38676 4.36076 8.61257C4.35206 7.7079 5.35242 7.08159 6.66594 7.08159C8.11863 7.08159 8.88412 7.87317 9.12769 8.75175L11.9983 8.43859Z"
          fill="#343A40"
        />
        <path
          d="M20.7971 18.5292C23.9112 18.5292 26.0511 17.0069 26.6079 14.6843L23.6677 14.3538C23.2414 15.4846 22.1976 16.0761 20.8406 16.0761C18.8051 16.0761 17.4568 14.7365 17.4307 12.4487H26.7383V11.4832C26.7383 6.79453 23.9199 4.73292 20.6318 4.73292C16.8043 4.73292 14.3078 7.54262 14.3078 11.6658C14.3078 15.8586 16.7696 18.5292 20.7971 18.5292ZM17.4394 10.3262C17.535 8.62127 18.7964 7.18597 20.6753 7.18597C22.4846 7.18597 23.7025 8.50818 23.7199 10.3262H17.4394Z"
          fill="#343A40"
        />
        <path
          d="M40.9543 4.90689H37.6053L34.5259 14.8322H34.3867L31.3161 4.90689H27.9583L32.7166 18.2682H36.1961L40.9543 4.90689Z"
          fill="#343A40"
        />
        <path
          d="M48.594 18.5292C51.7082 18.5292 53.8481 17.0069 54.4048 14.6843L51.4646 14.3538C51.0384 15.4846 49.9945 16.0761 48.6375 16.0761C46.602 16.0761 45.2537 14.7365 45.2276 12.4487H54.5353V11.4832C54.5353 6.79453 51.7169 4.73292 48.4287 4.73292C44.6013 4.73292 42.1047 7.54262 42.1047 11.6658C42.1047 15.8586 44.5665 18.5292 48.594 18.5292ZM45.2363 10.3262C45.332 8.62127 46.5933 7.18597 48.4722 7.18597C50.2816 7.18597 51.4994 8.50818 51.5168 10.3262H45.2363Z"
          fill="#343A40"
        />
        <path
          d="M60.3482 10.4393C60.3482 8.50818 61.5138 7.39474 63.1753 7.39474C64.802 7.39474 65.7762 8.46469 65.7762 10.2479V18.2682H68.9252V9.76081C68.9339 6.55966 67.1072 4.73292 64.3496 4.73292C62.3489 4.73292 60.9745 5.68978 60.3656 7.17727H60.209V4.90689H57.1993V18.2682H60.3482V10.4393Z"
          fill="#343A40"
        />
        <path
          d="M78.7983 4.90689H76.1626V1.70575H73.0136V4.90689H71.1173V7.34255H73.0136V14.7713C72.9962 17.2852 74.823 18.5205 77.189 18.4509C78.085 18.4248 78.7026 18.2508 79.0419 18.1377L78.5112 15.676C78.3373 15.7195 77.9806 15.7978 77.5892 15.7978C76.7976 15.7978 76.1626 15.5194 76.1626 14.2494V7.34255H78.7983V4.90689Z"
          fill="#343A40"
        />
        <path
          d="M84.8679 10.4393C84.8679 8.50818 86.0683 7.39474 87.7559 7.39474C89.4086 7.39474 90.3829 8.44729 90.3829 10.2479V18.2682H93.5318V9.76081C93.5318 6.53356 91.7051 4.73292 88.9302 4.73292C86.8773 4.73292 85.5725 5.66369 84.9549 7.17727H84.7983V0.453125H81.7189V18.2682H84.8679V10.4393Z"
          fill="#343A40"
        />
      </g>
      <g id="sense">
        <path
          d="M11.9983 29.5963C11.5633 27.3346 9.754 25.8906 6.62244 25.8906C3.4039 25.8906 1.21181 27.4738 1.22051 29.9443C1.21181 31.8928 2.41224 33.1802 4.97837 33.7108L7.25745 34.1892C8.48398 34.4589 9.0581 34.9547 9.0581 35.7115C9.0581 36.6249 8.06644 37.3121 6.57025 37.3121C5.12625 37.3121 4.18679 36.6858 3.91712 35.4854L0.846458 35.7811C1.2379 38.2342 3.29951 39.6869 6.57895 39.6869C9.91927 39.6869 12.2766 37.9558 12.2853 35.4245C12.2766 33.5194 11.0501 32.3538 8.52747 31.8058L6.24839 31.3187C4.89139 31.0142 4.35206 30.5445 4.36076 29.7703C4.35206 28.8656 5.35242 28.2393 6.66593 28.2393C8.11863 28.2393 8.88412 29.0309 9.12769 29.9095L11.9983 29.5963Z"
          fill="#343A40"
        />
        <path
          d="M20.7971 39.6869C23.9112 39.6869 26.0511 38.1646 26.6079 35.842L23.6677 35.5115C23.2414 36.6423 22.1976 37.2338 20.8406 37.2338C18.8051 37.2338 17.4568 35.8942 17.4307 33.6064H26.7383V32.6409C26.7383 27.9522 23.9199 25.8906 20.6318 25.8906C16.8043 25.8906 14.3078 28.7003 14.3078 32.8235C14.3078 37.0163 16.7696 39.6869 20.7971 39.6869ZM17.4394 31.4839C17.535 29.779 18.7964 28.3437 20.6753 28.3437C22.4846 28.3437 23.7025 29.6659 23.7199 31.4839H17.4394Z"
          fill="#343A40"
        />
        <path
          d="M32.5513 31.597C32.5513 29.6659 33.7169 28.5524 35.3784 28.5524C37.0051 28.5524 37.9793 29.6224 37.9793 31.4056V39.4259H41.1283V30.9185C41.137 27.7174 39.3102 25.8906 36.5527 25.8906C34.552 25.8906 33.1776 26.8475 32.5687 28.335H32.4121V26.0646H29.4023V39.4259H32.5513V31.597Z"
          fill="#343A40"
        />
        <path
          d="M54.8897 29.5963C54.4548 27.3346 52.6455 25.8906 49.5139 25.8906C46.2954 25.8906 44.1033 27.4738 44.112 29.9443C44.1033 31.8928 45.3037 33.1802 47.8698 33.7108L50.1489 34.1892C51.3754 34.4589 51.9495 34.9547 51.9495 35.7115C51.9495 36.6249 50.9579 37.3121 49.4617 37.3121C48.0177 37.3121 47.0782 36.6858 46.8086 35.4854L43.7379 35.7811C44.1294 38.2342 46.191 39.6869 49.4704 39.6869C52.8107 39.6869 55.1681 37.9558 55.1768 35.4245C55.1681 33.5194 53.9416 32.3538 51.4189 31.8058L49.1398 31.3187C47.7828 31.0142 47.2435 30.5445 47.2522 29.7703C47.2435 28.8656 48.2439 28.2393 49.5574 28.2393C51.0101 28.2393 51.7756 29.0309 52.0191 29.9095L54.8897 29.5963Z"
          fill="#343A40"
        />
        <path
          d="M63.6885 39.6869C66.8027 39.6869 68.9426 38.1646 69.4993 35.842L66.5591 35.5115C66.1329 36.6423 65.089 37.2338 63.732 37.2338C61.6965 37.2338 60.3482 35.8942 60.3221 33.6064H69.6298V32.6409C69.6298 27.9522 66.8114 25.8906 63.5233 25.8906C59.6958 25.8906 57.1993 28.7003 57.1993 32.8235C57.1993 37.0163 59.661 39.6869 63.6885 39.6869ZM60.3308 31.4839C60.4265 29.779 61.6878 28.3437 63.5667 28.3437C65.3761 28.3437 66.5939 29.6659 66.6113 31.4839H60.3308Z"
          fill="#343A40"
        />
      </g>
    </Icon>
  );
};
